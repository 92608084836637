import { effect, signal } from "@lit-labs/preact-signals";
import { authStore } from "./authStore";

/*
check SSE (with effect) -> update values here (mentors, sessions)

initially put the data (sessions, topics, mentors) from the requests from mentoring or mentor-listeners -> 
after, when user changes pages and when he clicks again on mentoring -> do not load all the sessions, topics and mentors again -> but check if sessionsStore has values for sessions / topics / mentors:
- if it has, take the data for the pages from sessionsStore (data here will be updated with sse)
- if no data, fetch it and put to sessionsStore
*/

function createSessionsStore() {
  // data
  const allSessions = signal([]);
  const allSessionTopics = signal([]);
  const mentors = signal([]);
  const selectedCSVSessionsByMentor = signal([]);

  // checks
  const sessionDataIsLoaded = signal(false);
  const mentorDataIsLoaded = signal(false);
  const hiddenMobileTimestamp = signal(null); // check for mobile to update the data, when the pwa was collapsed

  // sse
  const sseMessage = signal(null);
  const sseReceived = effect(() => _handleSSE());
  //sseReceived(); => to stop "effect"

  function _handleSSE() {
    console.log("SSE received in sessionsStore: ", sseMessage.value);

    // update values here
    let slug = sseMessage.value?.slug;
    let data = sseMessage.value?.data;

    if (slug === "mentor-sessions") {
      updateSessions(data);
    } else if (slug === "users" && data?.role === "mentor") {
      updateMentors(data);
    }
  }

  function updateMentors(data) {
    setTimeout(() => {
      let cohortCheck = data.programmes.some((item) => {
        return item.cohorts.some((cohort) => {
          let cohortLS = authStore.cohort.value;
          return cohort.id === cohortLS[0].id;
        });
      });
      if (data._deleted || !cohortCheck) {
        return;
      }
      // if new mentor -> add in mentors
      if (mentors.value && !mentors.value.find((item) => item.id === data.id)) {
        console.log("sessionStore: added new mentor:", data);
        let mentorsData = [...mentors.value];
        mentors.value = [...mentorsData, data];
      } else {
        // if there is such mentor -> update it in mentors
        console.log("sessionStore: updated the mentor:", data);
        let mentorsData = [...mentors.value].map((item) => {
          if (item.id === data.id) {
            return data;
          } else return item;
        });
        mentors.value = [...mentorsData];
      }
      console.log("sessionStore mentors's data:", mentors.value);
    }, 0);
  }

  function updateSessions(data) {
    setTimeout(() => {
      //if new session -> add in allSessions
      if (!allSessions.value.find((item) => item.id === data.id)) {
        console.log("sessionStore: added new session:", data);
        allSessions.value = [...allSessions.value, data];
      } else {
        // if there is such session -> update it in allSessions
        console.log("sessionStore: updated the session:", data);
        allSessions.value = [...allSessions.value].map((item) => {
          if (item.id === data.id) {
            return data;
          } else return item;
        });
      }
      console.log("sessionStore session's data:", allSessions.value);
    }, 0);
  }

  // mentor can select sessions to load with CSV button
  function selectSessionToSelectedCSV(session) {
    // if there is such session -> remove from selected
    if (
      !selectedCSVSessionsByMentor.value.find((item) => item.id === session.id)
    ) {
      console.log(
        "sessionStore: add new session to selectedCSVSessionsByMentor:",
        session
      );
      selectedCSVSessionsByMentor.value = [
        ...selectedCSVSessionsByMentor.value,
        session,
      ];
    } else {
      // if there is no such session -> add
      console.log(
        "sessionStore: remove session from selectedCSVSessionsByMentor:",
        session
      );
      selectedCSVSessionsByMentor.value = [
        ...selectedCSVSessionsByMentor.value,
      ].filter((item) => item.id !== session.id);
    }
    console.log(
      "sessionStore: updated  selectedCSVSessionsByMentor:",
      selectedCSVSessionsByMentor.value
    );
  }

  return {
    allSessions,
    mentors,
    allSessionTopics,
    sseMessage,
    sseReceived,
    sessionDataIsLoaded,
    mentorDataIsLoaded,
    selectedCSVSessionsByMentor,
    selectSessionToSelectedCSV,
    updateSessions,
    updateMentors,
    hiddenMobileTimestamp,
  };
}
export const sessionsStore = createSessionsStore();
