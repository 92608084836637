import { fromFetch } from "rxjs/fetch";
import { switchMap, timer, retry, mergeMap, tap } from "rxjs";

export const fetchRetryWrapper = (url, options, stopTokenRedirect = false) => {
  return fromFetch(url, options).pipe(
    switchMap((response) => {
      if (response.ok) {
        console.log(response, "response in fetchRetryWrapper");
        return response.json();
      } else {
        console.log(response, "response in fetchRetryWrapper");

        if (response?.status === 401 && !stopTokenRedirect) {
          alert("You need to logout and login. Sorry :(");
          console.log("REDIRECT lo login page");
          localStorage.clear();
          window.location.replace(`/auth.html?v=${new Date().getTime()}`);
        }

        throw new Error(
          `Error fetchRetryWrapperSessions: ${response?.status} ${response?.statusText}`
        );
      }
    }),
    tap({
      error: (err) => console.log(err, "error in fetchRetryWrapper"),
    }),
    retry({
      count: 4,
      delay: (error, retryCount) => {
        console.log(error, "error in fetchRetryWrapper -> make another try");
        const interval = 1000;
        const delay = Math.pow(2, retryCount - 1) * interval; // 1, 2, 4, 8
        //console.log(retryCount, delay);
        return timer(delay);
      },
    })
  );
};

export const fetchRetryWrapperSessions = (url, options) => {
  return fromFetch(url, options).pipe(
    switchMap((response) => {
      if (response.ok) {
        console.log(response, "response in fetchRetryWrapperSessions");
        return response.json();
      } else {
        if (response?.status === 401) {
          alert("You need to logout and login. Sorry :(");
          console.log("REDIRECT lo login page");
          localStorage.clear();
          window.location.replace(`/auth.html?v=${new Date().getTime()}`);
        }

        throw new Error(
          `Error fetchRetryWrapperSessions: ${response?.status} ${response?.statusText}`
        );
      }
    }),
    tap({
      error: (err) => console.log(err, "error in fetchRetryWrapperSessions"),
    }),
    retry({
      count: 4,
      delay: (error, retryCount) => {
        console.log(
          error,
          "error in fetchRetryWrapperSessions -> make another try"
        );
        const intervals = [15000, 30000, 45000, 60000]; // 15 sec, 30 sec, 45 sec, 1 min
        const delay =
          intervals[retryCount - 1] || intervals[intervals.length - 1];

        return timer(delay);
      },
    })
  );
};

// export const fetchRetryWrapper = (url, options) => {
//   return fromFetch(url, options).pipe(
//     switchMap((response) => {
//       if (response.ok) {
//         // OK return data
//         return response.json();
//       } else {
//         // Server is returning a status requiring the client to try something else.
//         return of({ error: true, message: `Error ${response.status }`, fullErr:response });
//       }
//     }),
//     map(responseObject=>{
//       console.log(responseObject, 'responseObject')
//       // want to check for something
//       if (responseObject.fullErr?.status === 401) {
//         console.log('auth error')
//         //display pop-up to user -> pass is wrong bro
//         //throw an error
//         return of(null)

//       } else if (responseObject?.message) {
//         console.log('good message')
//         return of(responseObject)
//       }
//     }),
//     retry({
//       count: 0,
//       delay: (retryCount) => {
//         const interval = 1000;
//         const delay = Math.pow(2, retryCount - 1) * interval; // 1, 2, 4, 8
//         //console.log(retryCount, delay);
//         return timer(delay);
//       },
//     }),
//     catchError((err) => {
//       console.error(err);
//       return of({ error: true, message: err.message, fullErr: err })
//     }),
//   );
// };
